import React, {useEffect, useRef, useState} from 'react';
import Categories from "./Categories";
import {getAllApps, getCategory} from "../api/Api";
import '../style/Home.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faStar} from '@fortawesome/free-solid-svg-icons'
import {useNavigate} from "react-router-dom";
import {useSetRecoilState} from "recoil";
import {isLoading} from "../api/Atoms";
import LoadGif from "../style/load.gif";

const Home = () => {

    const [topApps, setTopApps] = useState([]);
    const [topGames, setTopGames] = useState([]);
    const [skip, setSkip] = useState(0)
    const navigate = useNavigate();
    const setLoadingState = useSetRecoilState(isLoading)

    useEffect(() => {
        setLoadingState(true)
        getAllApps(skip).then((data) => {
            setTopApps(data)
            setLoadingState(false);
            setSkip(skip + 50)
        });

        getCategory('Casual', 0).then((data) => {
            setTopGames(data);
        });
    }, []);

    const goTo = (path) => {
        navigate(encodeURIComponent(path))
    }


    const imageRefs = useRef(new Map());

    const loadImage = (image) => {
        const src = image.dataset.src;
        // Only swap the `src` if the new `src` is valid
        if (src) {
            image.src = src;
            image.classList.add('loaded');
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries, imgObserver) => {
                entries.forEach((entry) => {
                    if (!entry.isIntersecting) return;

                    const lazyImage = entry.target;
                    loadImage(lazyImage);
                    imgObserver.unobserve(lazyImage); // Stop observing the current target
                });
            },
            {
                rootMargin: '0px 0px 200px 0px', // Load images 200px before they come into view
            }
        );

        imageRefs.current.forEach((img) => observer.observe(img));

        // Cleanup observer on component unmount
        return () => {
            observer.disconnect();
        };
    }, [topApps]); // Re-run effect if `topApps` changes

    const loadMore = () => {
        setLoadingState(true);
        getAllApps(skip).then((data) => {
            setTopApps(prevState => [...prevState, ...data])
            setSkip(skip + 50)
            setLoadingState(false);
        });
    }

    return (
        <div className="Home-Container">
            <div className="area">
                <span>Discover the Latest Apps at The App Lists</span>
                <p>In 2024, The App Lists offers a curated selection of the finest Android and iPhone applications,
                    featuring the latest technology, user-friendly designs, and versatile functionality. Our diverse
                    categories range from Dating and Finance to Entertainment and Education, ensuring there’s something
                    for every interest and need.</p>
                <p>Whether you’re looking for thrilling games, engaging educational tools, or exciting arcade
                    experiences, The App Lists is your ultimate destination for exploring the future of mobile apps,
                    where innovation meets practicality in every category.</p>

                <ul className="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div>
            <Categories/>

            <h1>Trending Games</h1>
            <div className='Home'>
                {topGames.slice(0, 20).map((result, index) => (
                    <div key={index} className='App-Container' onClick={() => goTo(result.title)}>
                        <img
                            ref={(el) => imageRefs.current.set(index, el)}
                            src={result.icon}
                            alt={result.title}
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = LoadGif;
                            }} // Fallback image on error
                            className="lazy-load"
                        />
                        <h3>{result.title}</h3>
                        {result.category ? <p>{result.category[0]}</p> : <span>&nbsp;</span>}
                        <p>
                            {result.rating}
                            <FontAwesomeIcon icon={faStar}/>
                        </p>
                    </div>
                ))}
            </div>

            <h1>Popular Apps</h1>
            <div className='Home' style={{marginBottom: 0}}>
                {topApps.map((result, index) => (
                    <div key={index} className='App-Container' onClick={() => goTo(result.title)}>
                        <img
                            ref={(el) => imageRefs.current.set(index, el)}
                            src={result.icon}
                            alt={result.title}
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = LoadGif;
                            }} // Fallback image on error
                            className="lazy-load"
                        />
                        <h3>{result.title}</h3>
                        {result.category ? <p>{result.category[0]}</p> : <span>&nbsp;</span>}
                        <p>
                            {result.rating}
                            <FontAwesomeIcon icon={faStar}/>
                        </p>
                    </div>
                ))}
            </div>
            <button style={{marginBottom: 40}} className='Load-More' onClick={() => loadMore()}>Load More</button>
        </div>
    );
};

export default Home;
