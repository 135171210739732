import axios from "axios";

const baseUrl = "https://api.theapplists.com";

export function getAllApps(skip) {
    return axios({
        method: 'get',
        url: `${baseUrl}/get_all_apps?skip=${skip}`
    }).then(function (response) {
        return response.data
    });
}

export function getSingleApp(app) {
    return axios({
        method: 'get',
        url: `${baseUrl}/get_app?title=${app}`
    }).then(function (response) {
        return response.data
    }).catch(function (error) {
        return [];
    });
}

export function getCategory(cat, skip) {
    return axios({
        method: 'get',
        url: `${baseUrl}/get_category?category=${cat}&skip=${skip}`
    }).then(function (response) {
        return response.data
    });
}

export function searchApp(value) {
    return axios({
        method: 'get',
        url: `${baseUrl}/search?q=${value}`
    }).then(function (response) {
        return response.data
    });
}
