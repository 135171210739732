import React from 'react';
import '../style/privacy-terms.css';

const Privacy = () => {
    return (
        <div className="Privacy-Container">
            <h1>Privacy Policy</h1>
            <p>Welcome to The App Lists, accessible from https://theapplists.com. Ensuring the privacy of our visitors is
                crucial to us. This document outlines the types of information we collect and how it is processed and
                used.</p>
            <p>For additional inquiries or more information about our Privacy Policy, please do not hesitate to contact
                us at gil@theapplists.com.</p>
            <p>This policy applies solely to information collected online through our website and does not extend to
                data gathered offline or via other channels.</p>

            <h2>Consent</h2>
            <p>By using our website, you agree to the terms of this Privacy Policy.</p>

            <h2>Information We Collect</h2>
            <p>We clearly specify the personal information we require from you and the reasons for its collection at the
                point of solicitation.</p>
            <p>Contacting us directly may lead us to receive further information about you, such as your name, email
                address, phone number, and the content of any messages or attachments you may send us.</p>
            <p>Registration on our site may involve providing contact details like your name, company name, address,
                email address, and telephone number.</p>

            <h2>Use of Your Information</h2>
            <p>We use the collected data for several purposes:</p>
            <ul>
                <li>Maintaining and operating our website</li>
                <li>Enhancing, personalizing, and expanding our website</li>
                <li>Understanding and analyzing how you use our website</li>
                <li>Developing new products, services, and features</li>
                <li>Communicating with you for customer service, updates, and marketing purposes</li>
                <li>Sending emails</li>
                <li>Preventing fraud</li>
            </ul>

            <h2>Log Files</h2>
            <p>The App Lists follows a standard procedure of using log files as part of our hosting services' analytics.
                These files log visitors when they visit websites. The information includes IP addresses, browser type,
                ISP, date and time stamp, referring/exit pages, and the number of clicks, none of which links to any
                personally identifiable information.</p>

            <h2>Cookies and Web Beacons</h2>
            <p>We use 'cookies' to enhance your experience by storing preferences and tracking information on how you
                use our website.</p>

            <h2>Google DoubleClick DART Cookie</h2>
            <p>Google, as a third-party vendor, uses cookies to serve ads on our site. You can opt out of the use of
                DART cookies by visiting the Google ad and content network Privacy Policy.</p>

            <h2>Advertising Partners</h2>
            <p>Some of our advertising partners may use cookies and web beacons. You can access their privacy policies
                below:</p>
            <ul>
                <li><a href="https://policies.google.com/technologies/ads">Google Ad Policies</a></li>
            </ul>

            <h2>Third-Party Privacy Policies</h2>
            <p>The App Lists's Privacy Policy does not apply to other advertisers or websites. We encourage you to review
                the privacy policies of these third-party ad servers for more detailed information on their
                practices.</p>

            <h2>CCPA Privacy Rights (Do Not Sell My Personal Information)</h2>
            <p>Under the CCPA, California consumers have the right to request that a business disclose the categories of
                personal data collected, delete personal data, and refrain from selling their personal data.</p>

            <h2>GDPR Data Protection Rights</h2>
            <p>We aim to ensure you are fully aware of all of your data protection rights. These include the rights to
                access, correct, erase, restrict, object to processing, and request data portability of your personal
                data.</p>

            <h2>Children's Information</h2>
            <p>We prioritize protecting children's privacy online. If you believe that your child has provided us with
                personal information, please contact us to have it removed promptly.</p>
        </div>


    );
};

export default Privacy;
