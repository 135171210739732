import React from 'react';
import { Menu, Input } from 'antd';
import { HomeOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import {Link} from "react-router-dom";
import '../App.css';

const { Search } = Input;




const Navbar = () => {
    const onSearch = value => {
        window.location.href = `/search?q=${encodeURIComponent(value)}`;
    };
    const menuItems = [
        {
            key: 'home',
            label: <Link style={{display: 'flex', alignItems: 'center', fontSize: '20px', color: 'black'}} to="/"><img src="/assets/Logo.png" alt='' width={70} style={{marginRight: '10px'}}/><h3 className="App-Name">The App Lists</h3></Link>,
        },
        {
            key: 'search',
            label: (
                <div style={{ float: 'right', margin: '8px', display: 'flex', order: 0 }}>
                    <Search
                        placeholder="Search Apps..."
                        allowClear
                        onSearch={value => {
                            window.location.href = `/search?q=${encodeURIComponent(value)}`;
                        }}
                        style={{ width: 200 }}
                    />
                </div>
            ),
        },
        // Add more items as needed
    ];

    return (
        <Menu mode="horizontal" theme="dark" items={menuItems} />
    );
};

export default Navbar;
