import React from 'react';
import {Link} from "react-router-dom";
import '../App.css';

const Footer = () => {
    return (
        <div className='Footer'>
            <div className='Footer-Links'>
                <Link to={'/'}>Home</Link>
                <Link to={'/terms-of-use'}>Terms</Link>
                <Link to={'/privacy-policy'}>Privacy</Link>
            </div>
            <div className="Footer-Logo">
                <img src="/assets/Logo.png" alt='' width={30} style={{marginRight: '10px'}}/>
                <p className="App-Name">The App Lists</p>
            </div>
        </div>
    );
};

export default Footer;
